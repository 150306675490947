.kautionen-matrix {
    overflow-x: auto;

    .kantonswappen {
        width: 30px;
        height: 30px;
        border:1px solid black;
    }
    .kautionsmatrix td {
        padding:2px;
        border: 1px solid black;
        text-align:center;

        a {
            position: relative;
            alttext {
                position: absolute;
                display: none;
                background-color: white;
                left:15px;
                top:15px;
                border:1px solid #cccccc;
                padding:3px;
                z-index:1;
                white-space: nowrap;
            }
        }
        a:hover {
            font-weight: bold;
            alttext {
                display: block;
            }
        }
    }
    .kautionsmatrix .branchentitle {
        text-align:right;
        padding:3px;
    }
    .kautionsmatrix tr:nth-child(even) {
        background: #CCC;
    }

    .kautionsmatrix tr.branche:hover {
        td {
            background-color: rgb(220, 38, 38);
            color: #fff;
            a {
                color: #fff;
                alttext {
                    color: rgb(220, 38, 38);
                }
            }
        }
    }

    .kautionsmatrix tr.branche td.hover {
        background-color: rgb(220, 38, 38);
        color: #fff;
        a {
            color: #fff;
            alttext {
                color: rgb(220, 38, 38);
            }
        }
    }
}
