section.content.faq {
    .card {
        position: relative;
        display: flex;
        flex-direction: column;
        min-width: 0;
        word-wrap: break-word;
        background-color: #fff;
        background-clip: border-box;

        .card-header {
            button {
                @apply text-dark hover:text-primary;

                span {
                    flex-basis: 100%;
                }
            }
        }
    }

    .accordion {
        overflow-anchor: none;

        .card {
            overflow: hidden;
        }
    }
}
