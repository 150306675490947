.alert {
    @apply relative py-3 px-5 mt-4;

    &.alert-success {
        @apply text-emerald-700 bg-emerald-200;
    }

    &.alert-danger {
        @apply text-red-700 bg-red-200;
    }

    &.alert-warning {
        @apply text-yellow-700 bg-yellow-100;
    }

    &.alert-info {
        @apply text-blue-700 bg-blue-200;
    }
}
