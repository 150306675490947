.card {
    @apply flex relative flex-col min-w-0 break-words bg-clip-border bg-white;

    .card-body {
        @apply flex-auto p-5;
    }

    .card-footer {
        @apply px-5 pb-5;
    }
}
