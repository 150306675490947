@tailwind base;

@layer base {
    @import './base/page';
    @import './base/cloak';
    @import './base/font';
    @import './base/typography';
    @import './base/code';
    @import './base/table';
    @import './base/list';
    @import './base/image';
    @import './base/form';
    @import './base/button';
}

@tailwind components;

@layer components {
    @import './components/alerts';
    @import './components/card';
    @import './components/video';
    @import './pages/faq';
    @import './pages/kaution';
    @import './pages/kautionsmatrix';
}

@tailwind utilities;