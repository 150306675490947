section.kaution-form {
    nav {
        ul {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 1rem;
        }
    }

    section {
        flex: 1 1 100%;

        > div {
            //display: none;

            input {
                border: none;
            }

            select {
                border: none;
            }
        }
    }

    .form-branche {
        #tab-taetigkeit:checked ~ nav .tab-taetigkeit,
        #tab-branche:checked ~ nav .tab-branche {
            label {
                font-weight: bold;
            }
        }

        #tab-taetigkeit:checked ~ section .tab-taetigkeit,
        #tab-branche:checked ~ section .tab-branche {
            display: block;
        }
    }

    .form-einsatzort {
        #tab-plz:checked ~ nav .tab-plz,
        #tab-kanton:checked ~ nav .tab-kanton {
            label {
                font-weight: bold;
            }
        }

        #tab-plz:checked ~ section .tab-plz,
        #tab-kanton:checked ~ section .tab-kanton {
            display: block;
        }
    }
}

#inputPLZselectInner {
    height: 150px;
    overflow: scroll;
    padding: 15px;
    z-index:1;
    ul span {
        margin-left: 68px;
    }
    ul li:hover {
        cursor: pointer;
        background-color:#999;
    }
}
